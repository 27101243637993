import { acceptHMRUpdate, defineStore } from 'pinia'

import { ENavPos, EToolbar, EViewAnimation, type State, type StateLayout } from '@/types'

export const useMainStore = defineStore('main', {
  state: (): State => ({
    layout: {
      navPos: ENavPos.left,
      toolbar: EToolbar.top,
      footer: true,
      boxed: false,
      roundedCorners: false,
      viewAnimation: EViewAnimation.fadeTop,
      quickPanel: false,
    },
    splashScreen: true,
    isLoading: false,
    readReport: {
      data: {
        totalEmails: 0,
        totalUnreadEmails: 0,
      },
    },
  }),
  actions: {
    setLayout(payload: Partial<StateLayout>) {
      if (payload && payload.navPos !== undefined) this.layout.navPos = payload.navPos

      if (payload && payload.toolbar !== undefined) this.layout.toolbar = payload.toolbar

      if (payload && payload.footer !== undefined) this.layout.footer = payload.footer

      if (payload && payload.boxed !== undefined) this.layout.boxed = payload.boxed

      if (payload && payload.roundedCorners !== undefined)
        this.layout.roundedCorners = payload.roundedCorners

      if (payload && payload.viewAnimation !== undefined)
        this.layout.viewAnimation = payload.viewAnimation
    },
    resetLayout() {
      this.layout.navPos = null
      this.layout.toolbar = null
    },
    setSplashScreen(payload: boolean) {
      this.splashScreen = payload
    },
    showLoading() {
      this.isLoading = true
    },
    hideLoading(tiemout: number = 0) {
      setTimeout(() => {
        this.isLoading = false
      }, tiemout * 1000)
    },
    openLoadingOverlayWhen<T>(promise: () => Promise<T>) {
      this.showLoading()
      try {
        return promise().finally(() => {
          this.hideLoading()
        })
      } catch (error) {
        this.hideLoading()
        throw error
      }
    },
    toggleQuickPanel() {
      this.layout.quickPanel = !this.layout.quickPanel
    },
  },
  getters: {
    navPos(state) {
      return state.layout?.navPos
    },
    toolbar(state) {
      return state.layout?.toolbar
    },
    footer(state) {
      return state.layout?.footer
    },
    boxed(state) {
      return state.layout?.boxed
    },
    roundedCorners(state) {
      return state.layout?.roundedCorners
    },
    viewAnimation(state) {
      return state.layout?.viewAnimation
    },
  },
  persist: {
    paths: ['layout'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
}
